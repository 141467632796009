$(document).foundation();

var image_index = "/assets/img/background.jpg";


$('.background').fillmore( { src: image_index, speed: 150 } );


$('.reveal-modal').on('opened', function(){
    $(window).trigger('resize');
});


// Slick SLider

var serviceSlider = $('.service-slider').slick({
  dots: true,
  arrows: true,
  prevArrow: '<span class="slick-prev"></span>',
  nextArrow: '<span class="slick-next"></span>',
  appendArrows: '.slider-nav'
});

$('.content-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  arrows: false,
  pauseOnHover: false,
});

$('.aerzte-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  arrows: true,
  prevArrow: '<div class="slick-prev"></div>',
  nextArrow: '<div class="slick-next"></div>',
  appendArrows: '.aerzte-slider-nav'
});


$(document).ready(function () {
  $('.doctor-menu li').hover(function() {
    $('.doctor-menu li').removeClass("medium");
    $(this).siblings().addClass("small");
    $(this).addClass("big");
  }, function () {
    $('.doctor-menu li').removeClass("small");
    $('.doctor-menu li').removeClass("big");
    $('.doctor-menu li').addClass("medium");
  });

  $(".gotoSlick a").on('click', function(event){
    event.preventDefault();
    var sectionToJumpTo = $("section[id='"+ $(this).attr('href').replace('#', '') + "']");
    var slideOfSection = sectionToJumpTo.closest(".service-slider-image");
    var slideOfSectionIndex = slideOfSection.prevAll().length - 1;
    if(slideOfSectionIndex >= 0) {
      $('.service-slider').slick('slickGoTo', slideOfSectionIndex);
    }
  })
});
